import React, { useEffect, useState } from 'react';
import Top from './Top';
import Header from '../components/Header';
import { Select, Input, Button, Modal, Pagination } from 'antd';
import { httpGet, httpPost, httpUrl } from '../api/httpClient';
import { useSelector, useDispatch } from "react-redux";
import { setCoinBalance, setCoinList, setCoinCurrency } from "../actions";
import { useNavigate } from "react-router-dom";
import SHA256 from 'crypto-js/sha256';
import { enc } from 'crypto-js';
import moment from "moment";
import Const from '../const';
import { formatBalance, multiplyBalance, stringFormat } from "../lib/util/coinUtil";

const Dividend = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const coinList = useSelector(state => state.coinListReducer);
    const string = useSelector(state => state.stringReducer.string);

    const [pagination, setPagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
    });
    const [list, setList] = useState([]);
    const [dividenModalVisible, setDividenModalVisible] = useState(false);
    const [transferAmount, setTransferAmount] = useState(0);
    const [transferErrMsg, setTransferErrMsg] = useState('');
    const [transferFee, setTransferFee] = useState('-');
    const [transferMin, setTransferMin] = useState('-');

    useEffect(() => {
        getGlobalSetting();
    }, []);

    useEffect(() => {
        getList();
    }, [pagination.current]);

    const getGlobalSetting = async () => {
        const result = await httpGet(httpUrl.globalSetting, [], {})
        result.data.forEach(value => {
            if (value.type == 'BBT_DIVIDEN_TRANSFER_FEE') setTransferFee(value.value)
            if (value.type == 'BBT_DIVIDEN_TRANSFER_MIN') setTransferMin(value.value)
        })
    }
    

    const handlePageChange = (page, pageSize) => {
        setPagination({ ...pagination, current: page, total: pageSize })
    };

    const getList = () => {
        let pageNum = pagination.current;
        let pageSize = pagination.pageSize;
        httpGet(httpUrl.dividenList, [pageNum, pageSize], {})
            .then(res => {
                console.log(res)
                const page = { ...pagination }
                page.total = res.data.totalPage;
                setList(res.data.list)
                setPagination(page);
            })
    }

    const requestBalance = async () => {
        console.log("##### Request balance")
        httpGet(httpUrl.balance, [0], {}).then(async (result) => {
            result = result.data;
            dispatch(setCoinBalance(result));
        }).catch(e => {
            console.log("## requestBalance error: " + e)
        });
    }
    const transfer = async () => {
        if (isNaN(transferAmount)) {
            setTransferErrMsg(string.bbtFreezeError);
            return;
        }
        setTransferErrMsg('');
        const result = await httpPost(httpUrl.dividenTransfer, [], {amount: parseFloat(transferAmount)});
        
        if (result.data == 'SUCCESS') {
            requestBalance();
            getList();
            setDividenModalVisible(false)
        }
        else if (result.data == 'INSUFFICIENT_BALANCE') alert(string.sendCoinLowBalance);
        else if (result.data == 'MIN_WITHDRAW_LIMIT') alert(string.bbtMinWithdrawLimit);
        else alert(string.bbtStakingCancelFail);
        
    }

    //코인이미지, 수량, 상태, 날짜
    const renderFlatList = (item) => {
        const coin = coinList.find(x => x.coinType == item.coinType);
        if (!coin) return;
        let statusString = '';
        if (item.from == 'DIVIDEND') {
            statusString = string.dividen;
        }
        if (item.to == 'CONVERT') {
            statusString = string.dividenTransfer;
        }
        if (item.to == 'DIVIDEND') {
            statusString = string.dividenCancel;
        }

        return (
            <div className='stake-list-container'>
                <div className={'stake-list-status'}>
                    [{statusString}]
                </div>
                <div className='stake-list-amount'>
                    {formatBalance(item.amount, coin.decimal, 4)} 
                </div>
                <div className='stake-list-createdate'>
                    {moment(item.createDate).format("YY-MM-DD")}
                </div>

            </div>
        );
    }

    return (
        <div id='bbtDividen'>
            <Top />
            <Header />
            <div className='content'>

                <div className=''>
                    <div className='btn-pre' onClick={() => navigate("/Staking")}>&lt; Back</div>
                </div>

                <div className='mybalance'>
                    <div className='btn-dividend' onClick={()=>setDividenModalVisible(true)}>{string.dividenTransfer}</div>
                    <div>{string.dividenUsdtBalance}</div>
                    <p className='balance'> {coinList.find(x=>x.coinType == 403).balance} <span className='won'>USDT</span></p>
                </div>

                <div className="list-container">
                    {list.map(item => {
                        if (!item) return;
                        return renderFlatList(item);
                    })}
                </div>

                <div className="page-container">
                    <Pagination
                        onChange={handlePageChange}
                        defaultCurrent={1}
                        pageSize={10}
                        total={pagination.total} />

                </div>
            </div>
            <Modal
                title={string.dividenTransfer}
                visible={dividenModalVisible}
                onOk={transfer}
                onCancel={()=>{setDividenModalVisible(false)}}
            >
            <p style={{marginBottom: '10px'}}>{string.bbtTransferExplain}</p>   
            
            <p style={{marginBottom: '0px'}}>{string.fee}: {transferFee}%</p>   
            <p style={{marginBottom: '20px'}}>{string.bbtMinWithdraw}: {transferMin} USDT</p>   
                
                <Input
                    autoComplete='false'
                    value={transferAmount}
                    onChange={(e)=>{setTransferAmount(e.target.value)}}
                />
                {transferErrMsg && (
                    <p style={{color: 'red'}}>{transferErrMsg}</p>   
                )}
            </Modal>
        </div>
    )
}
export default Dividend;
