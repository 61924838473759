const string = {
  appName: 'BlockBall Token Wallet',
  appDownload: '下载应用',
  logout: '登出',
  
  completed: '完成',
  ok: '确认',
  cancel: '取消',
  fail: '失败',
  sent: '转出',
  sending: '转出中',
  received: '转入',
  receiving: '转入中',
  value: '金额',
  myValue: '持有余额',
  fee: '手续费',
  more : '更多',
  welcome: '欢迎使用BBT Wallet！',
  localCoinUnit: ['韩元', 'USD', 'CNY', 'JPY'],
  error: '错误',
  errorDetail: '发生错误。请联系管理员。',
  myTotalAsset: 'My asset',

  popupNoshow: '%s天内不再显示',

  
  /*********signUp**********/
  signUp: '注册',
  signUpMsg01: '注册请求已完成。',
  signUpMsg02: '认证邮件已发送至注册的邮箱。',
  signUpMsg03: '完成邮件认证后可登录。',
  signUpRuleMsg01: '该邮箱地址已注册。',
  signUpRuleMsg02: '邮箱格式不正确。',
  signUpRuleMsg03: '密码复杂度不满足要求。',
  signUpRuleMsg04: '注册过程中发生未知错误。',
  signUpRuleMsg05: '两次输入的密码不一致。',
  signUpRuleMsg06: '请同意条款。',
  signUpRuleMsg07: '请输入8到15位的英文、数字、特殊字符组合。',
  signUpRuleMsg08: '请正确输入手机号码',
  signUpRuleMsg09: '请输入邮箱',
  signUpRuleMsg10: '请输入密码',
  signUpRuleMsg11: '请确认密码',
  signUpRuleMsg12: '请输入姓名',
  signUpRuleMsg13: '请输入电话号码',
  signUpRuleMsg14: '请输入4位数字的安全密码',
  signUpRuleMsg15: '请再次输入安全密码',

  signUpFailTitle: '注册失败',
  signUpFail: '注册失败。',
  signUpCancleMsg: '取消注册并返回主页吗？',
  signUpAgreement: '个人信息收集与利用',
  signUpOtherLoginMsg: '<span style="color:#00aab6; font-weight:bold;">使用Naver</span>或<span style="color:#00aab6; font-weight:bold;">Kakao</span>登录可<span style="color:#00aab6; font-weight:bold;">无需单独注册</span>。',
  emailRule03: '请输入正确的邮箱格式。',
  cancle: '取消',
  idEmail: '用户名（邮箱）',
  passwordRule: '密码（8到15位的英文、数字、特殊字符组合）',
  passwordConfirm: '确认密码',
  name: '姓名',
  inputTel: '输入电话号码',
  inputSecurity: '输入4位数的安全密码',
  inputSecurityConfirm: '确认安全密码',

  dupCheck: '重复检查',
  emailDuplCheckError: '邮箱重复检查失败。',
  emailDuplCheckErrorEmailDupl: '该邮箱已注册。',
  emailDuplCheckErrorInvalidEmail: '邮箱格式不正确。',
  needEmailDuplCheck: '需要进行邮箱重复检查。',

  sendCertiCode: '发送验证码',
  certiCodeFail: '请输入验证码。',
  certiCodeWrong: '验证码输入错误。',
  certiCodeSendFail: '验证码发送失败。',
  certiCodeNotVerified: '需要进行电话号码验证。',

  signUpErrorTitle: '钱包创建失败',
  signUpErrorIdDuplication: '该邮箱已注册。请确认邮箱。',
  signUpErrorInvalidEmailType: '邮箱格式不正确。请重新确认。',
  signUpErrorMaxPhone: '已超过每个电话号码可注册的账户数量。',
  signUpErrorMessage: '钱包创建失败。请稍后再试。',

  emailResent: '认证邮件已重新发送。',
  emailResendFailed: '认证邮件重新发送失败。',
  invalidEmail: '邮箱不正确。',
  emailAlreadyVerified: '邮箱已认证。',
  emailRecentlySent: '1分钟内不能重新发送邮件。请稍后再试。',
  inputJoinEmail: '请输入注册的邮箱。',

  emailResendGuide1: '没有收到认证邮件或认证时间已过期？',
  emailResendGuide2: '点击下方重新发送认证邮件按钮再次请求认证。',
  emailResend: '重新发送认证邮件',
  
  findPasswordDescription: '输入注册邮箱，密码重置链接将发送至邮箱。',
  findPasswordEmailSentSuccess: '重置密码的邮件已发送。',

  findId: '找回用户名',
  mnemonicError: '找回用户名失败。',

  /*********시세**********/
  criteriaCoinmarketcap: '（Coinmarketcap标准）',
  updated: '更新',
  currency: '行情',
  notice: '公告',
  krw: '韩元',
  news: '新闻',
  note1: '公告',
  
  /*********투자*************/
  miningAccued: '累计挖矿',
  miningInvestment: '我的投资资产',
  miningGiveInfo: '挖出的ZZ Coin将在次日01:00（GMT+9）统一发放。',
  miningToday: '今日挖矿情况',
  miningDetail: '详细内容',
  miningDoInvestment: '投资',
  coinNameZZ: 'ZZ',
  coinNameETH: 'ETH',


  /*********거래**********/
  selectCoin: '请选择币种。',
  sendCoinInputAmount: '请输入金额。',
  sendCoinInputAmountLow: '最小转出数量为0.000001。',
  sendCoinInputFee: '请输入手续费。',
  sendCoinInputAddress: '请输入地址。',
  sendCoinInputAddress2: '地址（请输入有效地址。）',
  sendCoinLowBalance: '余额不足。',
  sendCoinCurrentBalace: '当前余额',
  sendCoinAvailableBalance: '可用余额',
  sendCoinChain: '链',
  sendCoinFee: '手续费: (内部转账-%s, 外部转账-%s)',
  sendCoinLowFee: '手续费不足。',
  sendCoinInputError: '生成转账信息失败。',
  sendCoinSuccess: '转账成功。',
  sendCoinNeedConfirm: '请确认注意事项并勾选。',
  sendCoinConfirmDlgTtl: '转账',
  sendCoinConfirmMessage: '将以下币种转账。转账后无法取消。确认转账吗？',
  sendCoinWarnMessage: '请求后无法取消！',

  sendCoinCautionConfirmDescriptionEx: [
    '请再次确认金额和地址是否正确。',
    '币种金额管理到小数点后6位，根据情况小数点后6位以下的金额可能会有误差。',
    '由于区块链特性，转账后无法取消。',
  ],

  sendCoinCautionConfirm: '确认转账注意事项',
  sendCoinCautionConfirm2: '同意注意事项。',
  sendCoinCautionFee: '为区块链转账需收取约0.01 ETH的手续费。',
  sendCoinCautionConfirmBtc: '在存取比特币（BTC）、比特币现金（BCH）、比特币黄金（BCG）时，请注意它们是不同的加密货币。',
  sendCoinCautionConfirmEth: '在存取以太坊（ETH）、以太坊经典（ETC）时，请注意它们是不同的加密货币。',
  sendCoinCautionConfirmXrp: 'Ripple（XRP）钱包需持有至少20XRP。转出时，余额中的20XRP将被保留，只能转出剩余金额。',
  sendCoinCautionConfirmErc20: '以太坊代币转账时，根据网络状况，以太坊手续费可能多于或少于设置的值。因此，转账时需持有相当于所需手续费3倍的以太坊。',
  sendCoinCautionConfirmDescription: [
    '币种金额管理到小数点后6位，根据情况小数点后6位以下的金额可能会有误差。',
    '转账完成可能需要超过1小时，转账状态可在交易记录中查看。',
    '在其他交易所存取时，可能受交易所政策影响。',
    '由于加密货币的特性，转账后无法取消。',
    '转账前请务必确认地址。',
    '只能转账至相同币种的地址。若转至其他币种地址，将无法找回币种。',
    '由于区块链过载导致的存取延迟问题，BBT Wallet无法处理。',
  ],
  sendCoinXrpUseTag: '使用标签',
  sendCoinFailedTitle: '转账失败',
  sendCoinFailed: '转账失败。请稍后再试。',
  sendCoinFailedInvalidAddress: '地址错误。',
  sendCoinFailedInsufficientBalance: '余额不足。',
  sendCoinFailedLocked: '币种被锁定。',
  sendCoinFailedInvalidOtp: 'OTP信息错误。',
  sendCoinFailedInvalidSecPassword: '认证信息错误。',
  sendCoinFailedNoSecPassword: '请输入4位安全密码。',
  sendCoinFailedInvalidDesTag: '无效的Destination Tag。',
  sendCoinFailedOtpRequired: '已超过无需OTP的日转账限额。',
  sendCoinFailedLimitExceed: '已超过日限额。',
  sendCoinFailedSecurityLevelRequired: '需完成OTP和KYC认证才能转账。',
  sendCoinFailedKycDay: 'KYC完成后24小时内无法转账。',
  sendCoinSuccessTitle: '转账请求完成',
  includeAmount: '包含金额',
  includedAmount: 'QR中包含的存入金额',
  includeAmountCaution: '包含金额的QR码在其他钱包中可能不兼容。',
  inputDestinationTag: '输入Destination Tag（可选）',
  inputOtpValidation: '请输入OTP。',
  feeRequestFailed: '手续费查询失败。',
  exemption: '免除（会员间转账）',

  /*********거래내역**********/
  noCoins: '无交易信息。',
  tradeLog: '交易记录',
  walletSend: '转出',
  walletReceive: '接收',
  selectCoin: '请选择币种。',
  copyComplete: '已复制',
  address: '钱包地址',
  priceInsert: '输入金额',
  copyAddress: '复制地址',
  qrContainPrice: 'QR中包含的存入金额',
  depositAddress: '存入地址',
  insertAddress: "输入地址",
  insertAmount: "输入数量",
  insertMemo: "备注（可选）",
  securityPw: "安全密码",
  pleaseInsertAddress: "请输入地址。",
  pleaseInsertAmount: "请输入数量。",
  pleaseInsertSecurityPw: "请输入安全密码。",
  pleaseInsertOtp: "请输入OTP。",
  externalTrade: "外部",
  adjustAccount: "结算",
  recieveUsdtCaution: '这是基于ERC20的USDT，存入其他链的USDT将无法找回，请注意。',

  /*********스테이킹**********/
  staking: '冻结',
  bbtStakingStaked: '冻结',
  bbtStakingUnstaking: '解冻中',
  bbtStakingUnstaked: '解冻完成',
  bbtStakingRelease: '解除',
  bbtStakingReleaseConfirm: '您要解除质押吗？ 解除后，资金将在一定时间后存入您的钱包。',
  bbtStakingCancelInvalidInfo: '质押信息不正确。',
  bbtStakingCancelInvalidUser: '用户无效。',
  bbtStakingCancelInvalidStatus: '不是质押状态。',
  bbtStakingCancelFail: '失败。',
  dividenUsdtBalance: '分配的USDT余额',
  dividenTransfer: '提现',
  dividen: '分配',
  dividenCancel: '取消分配',
  bbtDoFreeze: '冻结',
  bbtDividenList: '分配明细',
  bbtFreezeExplain: '请输入冻结数量。',
  bbtUnFreezeExplain: '冻结后随时可以解除冻结，但解除冻结请求后需要%s小时才能重新获得BBT。',
  bbtFreezeError: '请输入数字。',
  bbtTransferExplain: '请输入提取数量。提取时扣除手续费后将以USDT支付到钱包。',
  bbtMinWithdrawLimit: '请输入不低于最低提取数量。',
  bbtMinWithdraw: '最小提现量',


  /*********약관 등**********/
  policyView: '查看条款',
  needAgreement: '请同意条款',
  policyTitle: '个人信息收集与利用条款',
  policyTitle2: '（必填）个人信息收集与利用条款',
  policyContent: [
    { title: '第一章 总则', content: '' },
    {
      title: '第一条（目的）',
      content: '本条款旨在规定BLC Inc.提供的App及钱包服务（以下简称“服务”）的使用条件和程序，以及公司与用户之间的权利义务和责任事项，其他必要事项。\n',
    },
    {
      title: '第二条（条款的说明和修改）',
      content: [
        '① 本条款的内容通过公司的服务等其他方法通知用户，并且用户同意本条款后生效。\n',
        '② 公司在不违反“条款规定法”、“信息通信网利用促进及信息保护等相关法律”等相关法律的范围内，可以修改本条款。\n',
        '③ 公司若修改条款，将在适用日期及修改理由等内容与现行条款一起在公司网站首页或公告栏中公布，适用日期为7天前。但对用户不利或重要内容的修改，应在适用日期30天前通知用户。\n',
        '④ 公司在通知修改条款时，如用户未在7日内明确表示反对意见，即视为用户同意修改条款。\n',
        '⑤ 用户若明确表示不同意修改条款，公司不能适用修改条款，用户可以解除使用合同。但若有特殊情况不能适用现行条款，公司可以解除使用合同。\n',
      ]
    },
    {
      title: '第三条（条款外的准则）',
      content: '公司可以为服务制定除本条款外的其他公告事项、个别条款、个别同意事项、使用指南、运营政策、详细指南（统称为“详细指南”），并告知用户或获得用户同意。若详细指南中获得用户同意的内容与本条款冲突，则优先适用详细指南。本条款未规定的事项或解释应遵循详细指南及相关法律或惯例。\n',
    },
    {
      title: '第四条（术语定义）',
      content: [
        '① 本条款中使用的术语定义如下。\n',
        '1.加密货币：指在区块链上电子存在的货币，如Tether（USDT）、Blockball Token（BBT）等。\n',
        '2.用户：指同意本条款并注册公司提供的钱包的用户。\n',
        '3.App：指可以存储或收发加密货币的智能手机专用在线程序。\n',
        '4.钱包：指智能手机上的加密货币存储App。\n',
        '5.密码：指用户为验证用户和钱包之间的同一性以及加密货币安全性而设置的字符组合。\n',
        '6.恢复邮箱：指钱包丢失时可恢复的首次注册邮箱。\n',
      ]
    },
    {
      title: '第二章 服务使用申请及批准（用户注册及解除）',
      content: '',
    },
    {
      title: '第五条（使用合同的成立）',
      content: [
        '① 用户在公司提供的App中创建钱包时表示同意本条款，即申请使用钱包。\n',
        '② 使用合同自用户在本App注册后，公司管理员在后台管理注册顺序中确认用户的钱包后成立。\n'
      ]
    },
    {
      title: '第六条（使用申请）',
      content: '使用申请自动在用户在公司提供的App中注册钱包时自动批准。\n',
    },
    {
      title: '第七条（用户信息使用同意及使用申请的批准）',
      content: [
        '① 用户信息使用同意\n',
        '1.公司将用户的个人信息用于本使用合同的履行和本使用合同上的服务提供目的。\n',
        '2.为方便用户使用公司及公司合作的服务，用户信息可能提供给公司合作的企业。但公司需在提供用户信息前事先告知合作企业、提供目的、提供的用户信息内容，并获得用户同意。\n',
        '3.用户可以通过修改用户信息随时查阅、修改个人信息。\n',
        '4.用户在使用申请表中填写用户信息并根据本条款申请使用时，视为用户同意公司根据本条款收集、使用及提供使用申请表中填写的用户信息。\n',
      ]
    },
    {
      title: '第八条（使用合同中止及解除）',
      content: [
        '① 使用合同及App使用的解除可以通过用户在智能手机中删除App来解除。\n',
        '② 公司在以下各项存在的情况下，可以不予批准申请，即使在申请后确认存在以下各项情况时，也可以取消批准或解除使用合同。\n',
        '1. 根据本条款曾被取消会员资格的情况\n',
        '2. 提供虚假或错误信息，或未填写公司要求的内容\n',
        '3. 申请者的责任导致无法批准，或违反本条款的其他情况\n',
        '4. 出于不正当用途或为不同的业务目的使用服务的情况\n',
        '5. 违反相关法律或可能危害社会治安或风俗的情况\n',
        '6. 申请者年龄未满19岁\n',
        '③ 公司可以在法律允许的范围内通过专业机构进行实名认证或身份验证，以确认用户提供的信息是否属实。\n',
      ]
    },
    { title: '第三章 公司的义务', content: '' },
    {
      title: '第九条 用户个人信息和服务的公司义务',
      content: [
        '① 公司遵守相关法律和本条款，尽最大努力提供持续和稳定的服务。\n',
        '② 为确保用户安全使用服务，公司可建立安全系统以保护个人信息，并公布和遵守个人信息处理方针。\n',
        '③ 公司可在业务中未经用户事先同意，将用户个人信息等处理为无法区分特定用户的信息统计资料，并使用该统计资料。为此，用户同意公司可以向用户设备发送Cookie，但用户可以通过设备设置拒绝或警告Cookie的发送，但公司在不承担责任的情况下，因拒绝或警告Cookie发送而导致的服务使用变更不承担责任。\n',
        '④ 公司应处理用户提出的与服务相关的意见或投诉，并通过服务内公告栏、电子邮件等方式向用户传达处理过程和结果。\n',
        '⑤ 公司遵守《信息通信网法》、《位置信息保护法》、《通信保密保护法》、《电信业务法》等与服务运营、维护相关的法律。\n',
      ]
    },
    { title: '第四章 用户的义务', content: '' },
    {
      title: '第十条（用户钱包和密码管理的用户义务）',
      content: [
        '① 用户对钱包和密码的管理负全部责任。由于用户的疏忽管理或不正当使用，导致的所有后果由用户自行承担，公司不承担责任。但公司故意行为导致的情况除外。\n',
        '② 用户必须记住注册邮箱和密码，恢复时可能需支付一定费用并进行现场身份验证等复杂过程。即使如此，100%恢复也可能不成功。最终因用户自身失误导致的泄露或丢失，公司不承担责任。\n',
      ]
    },
    {
      title: '第十一条（信息提供）',
      content: [
        '1.公司可以通过有线、无线电话、电子邮件、短信（LMS/SMS）、社交网络服务（SNS）等方式提供以下服务的使用指南和商品信息。在此情况下，用户可随时拒绝接收。\n',
        '① 事件和活动等服务\n',
        '② 其他公司不时决定提供给用户的服务\n',
        '2.用户可以通过服务随时查阅、修改或请求修改其个人信息。用户在申请注册时填写的信息如有变更，应在服务中直接修改或通过客服中心通知公司。由于用户未通知公司变更情况导致的损失，公司不承担责任。\n',
      ]
    },
    { title: '第五章 服务使用总则', content: '' },
    {
      title: '第十二条（服务种类）',
      content: [
        '① 公司提供的服务包括存储加密货币的钱包功能和向他人发送加密货币的功能，但仅在公司个别批准下可以发送BBT。\n',
        '② 公司提供的服务种类可能根据公司情况不时更改，提供服务的著作权及知识产权归公司所有。\n',
        '③ 公司仅根据公司规定的使用条件授予用户帐户、钱包、服务的使用权，用户不得利用这些使用权提供类似服务或从事商业活动。\n',
      ]
    },
    {
      title: '第十三条（服务内容的公告及变更）',
      content: [
        '① 公司通过网站公告各服务的特性、程序和方法等内容，用户应理解并使用公司公告的各服务内容。\n',
        '② 在有正当理由的情况下，公司可根据运营和技术需要变更提供的个别服务的全部或部分。在此情况下，公司应在最少7日前通知用户。\n',
        '③ 收到的BBT可根据公司运营和技术修改、变更的需要，通过钱包和Token的更新进行重置和重新发送。用户应在发生这种情况时积极配合公司。\n',
      ]
    },
    {
      title: '第十四条（服务的维持及中止）',
      content: [
        '① 在不影响公司业务或技术情况下，服务原则上24小时提供。但公司在定期检查等需要时可事先或事后通知用户。\n',
        '② 公司可将服务划分为一定范围，各范围内可使用时间另行规定。在此情况下，公司应事先或事后通知用户。\n',
        '③ 在以下各项情况发生时，公司可中止服务。在不可避免的情况下，公司应事先通知用户。\n',
        '1. 服务设备的维护或其他不可避免的情况\n',
        '2. 电信事业法规定的电信业务中止\n',
        '3. 非公司直接提供的服务由合作企业等第三方提供的服务中止\n',
        '4. 其他不可抗力的情况\n',
        '④ 在国家紧急状态、停电、服务设备故障或服务使用暴增等情况下，公司可限制或中止部分或全部服务。\n',
      ]
    },
    {
      title: '第十五条 使用限制',
      content: [
        '① 公司在以下各项情况发生时，可限制用户的服务使用和访问。\n',
        '1. 密码和安全密码连续错误\n',
        '2. 发生黑客攻击或事故\n',
        '3. 怀疑身份盗用\n',
        '4. 国家机关根据相关法律要求限制服务\n',
        '5. 涉嫌洗钱、不公平交易、犯罪行为\n',
        '6. 注册用户年龄未满19岁\n',
        '7. 发生或为防止上述各项情况发生的其他情况\n',
        '② 在以下各项情况发生时，公司可限制用户的存取款使用。\n',
        '1. 注册用户年龄未满19岁\n',
        '2. 发生或怀疑发生黑客攻击或诈骗事故\n',
        '3. 怀疑身份盗用\n',
        '4. 国家机关根据相关法律要求限制服务\n',
        '5. 涉嫌洗钱、不公平交易、犯罪行为\n',
        '6. 用户本人请求\n',
        '7. 加密货币已发送但未反映在用户账户中，或实际存取款请求与不同\n',
        '8. 发生或为防止上述各项情况发生的其他情况\n',
        '③ 根据本条款限制服务使用或解除使用合同的情况，公司应通过电子邮件、短信、App推送等方式通知用户。\n',
        '④ 用户可按照公司规定的程序对限制使用等情况提出异议。如果公司认为异议合理，公司应立即恢复服务使用。\n',
      ]
    },
    {
      title: '第十六条（使用合同解除）',
      content: [
        '① 用户可随时通过客服中心申请解除使用合同，公司应按照相关法律规定立即处理。\n',
        '② 在以下各项情况发生时，公司可设定时间限制用户的服务使用并同时要求改正。若在合理时间内未改正或重复违反规定，公司可解除使用合同。\n',
        '1. 违反本条款第十条规定的用户义务或第十五条规定的使用限制情况\n',
        '2. 违反著作权法的非法程序提供及运营妨碍、违反《信息通信网利用促进及信息保护等相关法律》的非法通信及黑客攻击、恶意程序传播、超出访问权限等行为\n',
        '3. 妨碍公司提供服务或尝试妨碍\n',
        '4. 发生与上述各项情况相似的其他情况\n',
        '③ 解除使用合同时，通过服务获得的所有权益将消失，公司不承担赔偿责任。\n',
        '④ 根据本条款解除服务使用合同时，公司应通过电子邮件、短信、App推送等方式通知用户。\n',
        '⑤ 使用合同解除时，除法律规定和个人信息处理方针外，公司应删除用户的所有信息。\n',
        '⑥ 根据第2项，公司解除使用合同的情况，公司可为接收和处理用户的异议，保留用户信息一段时间，时间过后删除用户信息（存取款记录除外）。\n',
      ]
    },
    {
      title: '第十七条（信息提供及广告刊登）',
      content: [
        '① 公司在运营服务时，可通过服务页面、电子邮件、短信、App推送等方式向用户提供各种服务相关信息。\n',
        '② 公司在运营服务时，可通过服务页面或经用户同意后通过电子邮件、短信、App推送等方式提供公司或合作企业的各种广告信息。\n',
      ]
    },
    { title: '第五章 个人信息保护', content: '' },
    {
      title: '第十八条（个人信息保护）',
      content: [
        '公司根据相关法律规定，努力保护用户的个人信息。用户的个人信息仅用于用户同意的目的和范围内。公司除法律规定或用户同意外，不向第三方提供用户个人信息。详情请参阅个人信息处理方针。\n',
      ]
    },
    { title: '第六章 赔偿及免责条款', content: '' },
    {
      title: '第十九条（公司的免责事项及赔偿）',
      content: [
        '① 公司不对服务及本条款未明示的任何事项作出保证。此外，不保证比特币、以太坊、BBT等加密货币的价值。\n',
        '② 公司因不可抗力如自然灾害、DDoS攻击、IDC故障、服务接入激增导致的服务器宕机、通信服务商线路故障等原因无法提供服务时，免责。但公司故意或重大过失的情况除外。\n',
        '③ 公司对区块链问题、加密货币发行管理系统自身缺陷或技术问题、通信服务商故障、定期服务器检查等导致的不可避免的障碍免责。但公司故意或重大过失的情况除外。\n',
        '④ 公司对用户的责任造成的服务使用障碍及结果不承担责任。但用户有正当理由的情况除外。\n',
        '⑤ 公司对用户之间或用户与第三方之间通过服务进行的交易等不承担责任。但公司故意或重大过失的情况除外。\n',
        '⑥ 公司对免费提供的服务使用不承担责任，法律另有特别规定的除外。\n',
        '⑦ 公司可为提供稳定服务进行定期、不定期或紧急服务器检查。\n',
        '⑧ 任何人因服务错误、系统故障或其他原因未经授权取得或接收第三方所有的加密货币时，公司可在通知当事人后回收或恢复原状。\n',
      ]
    },
    {
      title: '第二十条（管辖法院及准据法）',
      content: [
        '① 本服务使用条款根据韩国法律解释和适用，与服务使用相关的公司与用户之间的争议诉讼适用韩国法律。\n',
        '② 与服务使用相关的公司与用户之间的诉讼应按法律规定程序提起，由相关法院管辖。\n',
      ]
    },
    { title: '附则', content: '本条款自2024年1月8日起生效。' },
  ],

  ///////////////////////////////////////////////////////////////////////////////

  login: '登录',
  password: '密码',

  loginFailedTitle: '登录失败',
  loginFailedWrongId: '用户名错误。',
  loginFailedWrongPassword: '密码错误。',
  loginFailedExpiredId: '账号已停用。',
  loginFailedEmailConfirm: '邮箱未验证。请验证邮箱后使用。',
  loginFailedMessage: '登录失败。请稍后再试。',
  loginFailedInvalidLoginInfo: '登录信息错误。',

  myinfoLogout: '登出',
  myinfoLogoutConfirm: '确认登出？',
  passwordModify: '修改密码',
  changePasswordFailed: '修改密码失败。',
  changePasswordSuccess: '密码修改成功。请登录。',

  savedId: '保存用户名',
  autoLogin: '自动登录',
  findPw: '找回密码',

  /*********내 정보************/
  myPage: '我的信息',
  myWallet: "我的钱包",
  exchange: "兑换",
  mining: "挖矿",
  notice: "公告",
  myWalletDialog: "钱包详情",
  walletReceiveMenu: "接收钱包",
  walletSendMenu: "发送钱包",
  inquiry: "1:1 咨询",
  phone: '电话号码',
  setupOtp: '设置OTP',
  changeOtp: '更改OTP',
  certiKYC: 'KYC认证',
  change: '更改',
  close: '关闭',
  saveData: '保存',
  nowPw: '当前密码',
  newPw: '新密码',
  changePasswordFailedSamePassword: '新旧密码相同。',
  changePasswordFailedInvalidPassword: '密码错误。',
  myinfoTwoSecurityInfo: '双重认证密钥是用于钱包转账时的6位数密钥。\n请从应用商店下载Google OTP或Authy。',
  myInfoOtpMail: '请输入邮件发送的\n6位认证码',
  otpCopied: 'OTP密钥已复制。',
  submit: '提交',
  inputPhone: '输入电话号码',
  sendCertiCode2: '发送验证码',
  phoneFail: '请正确输入手机号码。',
  checkCertiCode: '确认验证码',
  certiCode: '验证码',
  certiCodeExpired: '认证过期。请重新发送验证码。',
  inputCertiCode: '输入验证码',
  inputCurrentSecPassword: '输入当前安全密码（4位数）',
  inputNewSecPassword: '输入新安全密码（4位数）',
  inputCurrentPassword: '输入当前密码',
  inputNewPassword: '输入新密码',
  kycOTPerror: '请先注册OTP',
  certiCompleted: '认证完成',
  auditing: '审核中',
  enterNicknameValidation: '姓名请用韩文或英文输入',
  setSucceed: '更改成功。',
  setFailed: '更改失败。请联系管理员。',
  kycInfo: '请在白纸上写上“BBT钱包”和今天的日期、用户名（邮箱），并与身份证一起拍照。',
  alreadyOtp: 'OTP已注册。',
  myinfoOtpComplete: 'OTP设置完成。',
  myinfoOtpError: '认证码或OTP码错误',
  changeOtpComplete: '认证完成。请重新设置OTP。',
  captureError: '拍照失败。请重新拍照。',
  fileUploadComplete: '文件已上传。',
  fileUploadError: '文件上传失败。请联系管理员。',

  /*********문의하기************/
  inquire : '咨询',
  myInquiry: '我的咨询',
  admin: '管理员',

  /*********교환************/
  exchangeUseCoin: '使用币种',
  exchangeReceiveCoin: '接收币种',
  exchangeUseAmount: '使用数量',
  exchangeReceiveAmount: '接收数量',
  exchangeRate: '汇率',
  coinNameZZ: 'ZZ',
  coinNameETH: 'ETH',
  RateInform: '兑换汇率可能会有所不同。',
  feeOneday1: '每日一次手续费免费',
  feeOneday2: '（24小时内无交易）',
  exchangeConfirmMessage: '确认将%s兑换为%s？',
  shortTermSecPassword: '安全密码',
  exchangeCoinInputAmount: '请输入数量。',
  exchangeCoinSuccess: '兑换请求已完成。',
  InvalidFranchise: '无效的商户信息。',
  InvalidCoinType: '无效的币种。',
  
  question: '问题',
  answer: '回答完成',
  registInquiry: '注册咨询',
  regist: '注册',
  registComplete: "咨询内容已注册。",
  writer: '作者',
  inquiryDetails: '咨询内容',
  
  /*********판매**********/
  bankWithdrawInputError: '请填写所有项目',
  bankWithdrawApply: '申请出售',
  bankWithdrawApplyConfirm: '确认申请出售？',
  bankWithdrawApplySuccess: '申请已完成。',
  bankWithdrawApplyInvalidAmount: '请正确输入数量',
  bankWithdrawApplyInvalidSecPassword: '请确认安全密码',
  bankWithdrawBank: '银行',
  bankWithdrawBankInput: '请输入银行名称',
  bankWithdrawDepositor: '账户持有人',
  bankWithdrawDepositorInput: '请输入账户持有人名称',
  bankWithdrawAccount: '账号',
  bankWithdrawAccountInput: '请输入账号（不含“-”）',
  bankWithdrawStatus: '状态',
  bankWithdrawApplyAmount: '申请数量',
  bankWithdrawApplyTime: '申请时间',
  
  bankDepositInputError: '请填写所有项目',
  bankDepositApply: '申请存款',
  bankDepositApplyConfirm: '确认申请存款？',
  bankDepositApplySuccess: '申请已完成。',
  bankDepositApplyInvalidAmount: '请正确输入数量',
  bankDepositDepositor: '账户持有人',
  bankDepositDepositorInput: '请输入账户持有人名称',
  bankDepositStatus: '状态',
  bankDepositApplyAmount: '申请数量',
  bankDepositApplyTime: '申请时间',
  
  /*********교환**********/
  exchangeCoinInputAmount: '请输入数量。',
  exchangeCoinLowBalance: '余额不足。',
  exchangeCautionConfirm: '确认兑换注意事项',
  exchangeCoinLowBalanceXrp: '（需持有至少20XRP）',
  exchangeCoinNeedConfirm: '请确认注意事项并勾选。',
  exchangeCoinInputError: '生成兑换信息失败。',
  exchangeCoinSuccess: '兑换请求已完成。',
  exchangeCautionConfirmDescription: [
    '根据与法币的兑换比率进行兑换。',
    'OTC市场管理币种金额到小数点后6位，0.0001以下的金额可能存在误差。',
    '兑换完成可能需要超过1小时，兑换状态可在交易记录中查看。',
    '加密货币特性决定了兑换后无法取消。',
    '由于区块链过载导致的延迟问题，OTC市场无法处理。',
  ],
  exchange: '交易',
  exchangeInfo: '交易记录',
  exchangeSendValue: '出售币种',
  exchangeReceiveValue: '接收币种',
  exchangeCoinConfirmDlgTtl: '进行交易',
  exchangeCoinConfirmMessage: '以下列方式进行币种出售登记。出售完成后无法取消。确认登记？',
  
  /*********교환*********/
  register: '注册者',
  registDate: '注册日期',
  doApply: '购买',
  doCancel: '取消',
  exchangeMyList: '我的记录',
  exchangeRegist: '出售登记',
  viewAll: '查看全部',
  exchangeSelectCoin: '选择币种',
  search: '搜索',

  enterAmount: '输入数量',

  exchangeSameCoinFail: '不能交易相同币种。',

  exchangeSendCoin: '发送币种',
  exchangeReceiveCoin: '接收币种',
  exchangeCoinInputAmount: '请输入币种数量',

  exchangePolicy01: '- 出售币种时，手续费将从总金额中扣除。',
  exchangePolicy02: '- 取消出售登记时，扣除取消手续费后的金额将退还。',
  exchangePolicy03: '- 一旦登记出售，将等待匹配或取消。',
  
  exchangeRegistNoBalance: '余额不足，无法出售。',
  exchangeRegistFail: '失败。请重试。',

  exchangeCancel: '取消交易',
  exchangeCancelMessage: '取消出售将产生取消手续费。确认取消？',
  exchangeCancelFee: '取消手续费',
  exchangeDone: '交易完成',
  
  exchangeApply: '购买',
  exchangeApplyMessage: '购买后无法取消。确认购买？',
  exchangeApplyNoBalance: '余额不足，无法购买。',
  exchangeInvaild: '已交易或已取消。',
  exchangeSelfFail: '不能与自己交易。',
  exchangeRegistDate: '注册日期时间',

  exchangeStatusPending: '交易待处理',
  exchangeStatusCancel: '取消完成',
  exchangeStatusMatch: '交易完成',
  exchangeStatus: '交易状态',

  exchangeMyRegist: '出售列表',
  exchangeMyApply: '购买列表',
  exchangeBuyCoin: '购买币种',
  exchangeSentCoin: '发送币种',

  exchangeStatusCode: [
    {code: '', name: '查看全部'},
    {code: 'PENDING', name: '交易待处理'},
    {code: 'MATCH', name: '交易完成'},
    {code: 'CANCEL', name: '取消完成'},
  ]
};

export default string;
