export default {
    serverProtocol: 'https',
    serverIp: 'back.blockballwallet.com',
    serverPort: '443',
  
    // serverProtocol: 'http',
    // serverIp: "localhost",  
    // serverPort: '18001',

    serverContext: '',
      
    enableForeignExchangeRate: false,
  
    appCode: 'bbt'
  };
  