import { Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

const Footer = (props) => {

    return (
            <div id="footer">
                {props.appName} ©2024<br/> Created by BlockBall. All Right Reserved.
            </div>
        );
}
export default Footer