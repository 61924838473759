
import React, { useEffect, useState } from 'react';
import { httpGet, httpUrl,} from "../api/httpClient";
import { useLocation, useNavigate } from "react-router-dom";
import con from '../const';
import { useSelector, useDispatch } from "react-redux";
import string from '../language/StringKo';
import { MenuOutlined } from '@ant-design/icons';
import { Modal, Popover } from "antd";
import { setLogout, stringReducer } from "../actions";

const Header = () => {
    
    const dispatch = useDispatch();
    const string = useSelector(state => state.stringReducer.string); 
    const location = useLocation();
    const navigate = useNavigate();
    const [currName, setCurrName] = useState('Currency');
    const [isApp, setIsApp] = useState(false);



    useEffect(()=>{
        requestCurrencyExchangeRate();
        checkApp();
    },[]);
    const checkApp = () => {
        const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
        const isAndroidWebView = /Android/.test(userAgent) && /wv/.test(userAgent);
        setIsApp(isAndroidWebView) //ios는 없음

    }
    
  const logout = () => {
    Modal.confirm({
        title: string.myinfoLogout,
        content: string.myinfoLogoutConfirm,
        okText: string.ok,
        cancelText: string.cancel,
        onOk() {
            dispatch(setLogout());
            navigate('/', {state: { logout: true}});
        },
        onCancel() {
        },
    });
  }
    const [isMenuOpen, setIsMenuOpen] = useState(false); 
    
    const toggleMenu = () => {
        setIsMenuOpen((prevState) => !prevState); 
    };
    const requestCurrencyExchangeRate = (hide) => {
        if (con.enableForeignExchangeRate) {
            console.log("##### Request currencyExchangeRate")
            return new Promise((resolve, reject) => {
                httpGet(httpUrl.currencyExchangeRate, [], {}, hide).then((result) => {
                    console.log("## Request currencyExchangeRate loaded")
                    result = result.data;
                    global.exchangeRate = [
                        1,
                        result.find(x => x.currency === 'KRWUSD').rate,
                        result.find(x => x.currency === 'KRWCNY').rate,
                        result.find(x => x.currency === 'KRWJPY').rate,
                    ]
                    resolve();
                }).catch(e => {
                    console.log("## requestCurrencyExchangeRate error: " + e)
                    reject();
                });
            })
        } else {
            return new Promise((resolve, reject) => {
                global.exchangeRate = [1, 1, 1, 1];
                resolve();
            })
        }
    }

    const menus = [
        {
            idx: 1,
            name: string.currency,
            url: "/Currency",
        },
        {
            idx: 2,
            name: string.myWallet,
            url: "/MyWallet",
        },
        {
            idx: 3,
            name: string.staking,
            url: "/Staking",
        },
        {
            idx: 14,
            name: 'Dividend',
            url: "/Dividend",
            parent: "/Staking",

        },
        {
            idx: 4,
            name: string.mining,
            url: "/Mining",
        },
        {
            idx: 5,
            name: string.notice,
            url: "/notice",

        },
        {
            idx: 6,
            name: string.myWalletDialog,
            url: "/myWalletDialog",
            parent: "/MyWallet",

        },
        {
            idx: 7,
            name: string.walletReceiveMenu,
            url: "/walletReceive",
            parent: "/MyWallet",

        },
        {
            idx: 8,
            name: string.walletSendMenu,
            url: "/walletSend",
            parent: "/MyWallet",

        },
        {
            idx: 9,
            name: string.inquiry,
            url: "/inquiry",

        },
        {
            idx: 10,
            name: string.exchange,
            url: "/Transaction",               
        },
        {
            idx: 11,
            name: string.received,
            url: "/Deposit",               
        },
        {
            idx: 12,
            name: string.sent,
            url: "/Withdraw",               
        },
        {
            idx: 13,
            name: string.myPage,
            url: "/Mypage",               
        },
        
    ];
    const menuShow = menus.filter(x => x.idx == 2 || x.idx == 3 || x.idx == 5 || x.idx == 9 || x.idx == 13)
    const currentPage = menus.find(
        (x) => x.url === location.pathname
    );

    return (
        <div id="header">     
            {/* <div className='pathName'>{currentPage.parent ? currentPage.parent.replace("/","") : location.pathname.replace("/", "")}</div> */}
            <div className='navi desk'>
                {menuShow.map((row) => {
                    return (
                        <div
                            key={row.idx}
                            onClick={() => navigate(row.url)}
                            className={"top-menu " + (row.idx === currentPage.idx || row.url === currentPage.parent ? "active" : "")}
                        >
                            <div className="top-text">{row.name}</div>
                            <div className={row.idx === currentPage.idx || row.url === currentPage.parent ? 'menudot active' : 'menudot'}/>                         
                        </div>
                    );
                })}
            </div>
            <div className='navi mobile'>

                <MenuOutlined onClick={toggleMenu} style={{color: '#888'}}/>
     	        {isMenuOpen && (
                    <div className='navi-content'>
                        {menuShow.map((row) => {
                            return (
                                <div
                                    key={row.idx}
                                    onClick={() => navigate(row.url)}
                                    className={"top-menu " + (row.idx === currentPage.idx || row.url === currentPage.parent ? "active" : "")}
                                >
                                    <div className={row.idx === currentPage.idx || row.url === currentPage.parent ? 'menudot active' : 'menudot'}/>   
                                    <div className="top-text">{row.name}</div>                      
                                </div>
                            );
                        })}
                        {!isApp && (
                            
                            <div
                                key={99}
                                onClick={() => {global.location.href = 'https://www.blockballwallet.com/bbtwallet.apk'}}
                                className={"top-menu "}
                            >
                                <div className={'menudot'}/>   
                                <div className="top-text">{string.appDownload}</div>                      
                            </div>
                        )}
                        <div
                            key={0}
                            onClick={() => logout()}
                            className={"top-menu "}
                        >
                            <div className={'menudot'}/>   
                            <div className="top-text">{string.logout}</div>                      
                        </div>
                    </div>
                )}
            </div>
        </div>

    )
}


export default Header;