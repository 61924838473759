import React, { useEffect, useState } from 'react';
import Top from './Top';
import Header from '../components/Header';
import { Select, Input, Button, Modal, Pagination  } from 'antd';
import { httpGet, httpPost, httpUrl } from '../api/httpClient';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCoinBalance, setCoinList, setCoinCurrency } from "../actions";
import SHA256 from 'crypto-js/sha256';
import { enc } from 'crypto-js';
import moment from "moment";
import Const from '../const';
import { formatBalance, multiplyBalance, stringFormat } from "../lib/util/coinUtil";

const Staking = () => { 
    const navigate = useNavigate();

    const coinList = useSelector(state => state.coinListReducer);   
    const string = useSelector(state => state.stringReducer.string);

    const [pagination, setPagination] = useState({
        total: 0,
        current: 1,
        pageSize: 4,
    });
    const [list, setList] = useState([]);
    const [totalStaked, setTotalStaked] = useState(0);
    const [freezeModalVisible, setFreezeModalVisible] = useState(false);
    const [freezeAmount, setFreezeAmount] = useState(0);
    const [freezeErrorMsg, setFreezeErrorMsg] = useState('');
    const [unstakeWaitTime, setUnstakeWaitTime] = useState('-');

    useEffect(() => {
        getGlobalSetting();
    }, []);

    useEffect(()=>{
        getList();
    },[pagination.current]);

    const getGlobalSetting = async () => {
        const result = await httpGet(httpUrl.globalSetting, [], {})
        result.data.forEach(value => {
            if (value.type == 'BBT_UNSTAKING_WAITTIME') setUnstakeWaitTime(value.value)
        })
    }

    const handlePageChange = (page, pageSize) => {
        setPagination({...pagination, current: page, total: pageSize})   
    };

    const getList = () => {
        let pageNum = pagination.current;
        let pageSize = pagination.pageSize;
        httpGet(httpUrl.stakingList, [pageNum, pageSize], {})
        .then( res => {
            console.log(res)
            const page = { ...pagination }
            page.total = res.data.totalCount;
            setList(res.data.list)
            setPagination(page);
            setTotalStaked(formatBalance(res.data.totalStaked, 9, 4))
        })
    }

    const dividend = () => {
        navigate("/Dividend");
    }

    const create = async () => { 
        if (isNaN(freezeAmount)) {
            setFreezeErrorMsg(string.bbtFreezeError);
            return;
        }
        setFreezeErrorMsg('');
        const result = await httpPost(httpUrl.stakingCreate, [], {amount: parseFloat(freezeAmount)});
        
        if (result.data == 'SUCCESS') {
            getList();
            setFreezeModalVisible(false)
        }
        else if (result.data == 'INSUFFICIENT_BALANCE') alert(string.sendCoinLowBalance);
        else alert(string.bbtStakingCancelFail);
    };
    const cancel = async (idx) => { 
        if (global.confirm(string.bbtStakingReleaseConfirm)) {
            alert(idx)
            const result = await httpPost(httpUrl.stakingCancel, [idx], {  })
            if (result.data == 'SUCCESS') {
                getList();
            }
            else if (result.data == 'INVALID_INFO') alert(string.bbtStakingCancelInvalidInfo);
            else if (result.data == 'INVALID_USER') alert(string.bbtStakingCancelInvalidUser);
            else if (result.data == 'INVAID_STATUS') alert(string.bbtStakingCancelInvalidStatus);
            else alert(string.bbtStakingCancelFail);
        }
    };
    
    //코인이미지, 수량, 상태, 날짜
    const renderFlatList = (item) => {
        const coin = coinList.find(x=>x.coinType == item.coinType);
        if (!coin) return;
        let statusString = '';
        let statusStyle = '';
        let date = '';
        if (item.status == 'STAKED') {
            statusString = string.bbtStakingStaked;
            statusStyle = 'staked';
            date = item.createDate
        }
        if (item.status == 'UNSTAKING') {
            statusString = string.bbtStakingUnstaking;
            statusStyle = 'unstaking';
            date = item.unstakingDate 
        }
        if (item.status == 'UNSTAKED') {
            statusString = string.bbtStakingUnstaked;
            statusStyle = 'unstaked'
            date = item.unstakedDate
        }



        return (
            <div className='stake-list-container'>
                <div className='stake-list-image'>
                    <img src={coin.image}/>
                </div>
                <div style={{flex:1}}>
                    <div className='stake-list-inner'>
                        <div className={'stake-list-status'}>
                            [{statusString}]
                        </div>
                        <div className='stake-list-amount'>
                            {formatBalance(item.amount, coin.decimal, 4)} {coin.symbol}
                        </div>
                    </div>
                    <div className='stake-list-inner'>
                        <div className='stake-list-createdate'>
                        {moment(date).format("YY-MM-DD")}
                        </div>
                        {item.status == 'STAKED' && (
                            <div className='btn-release' onClick={()=>cancel(item.idx)}>
                                {string.bbtStakingRelease}
                            </div>
                        )}
                        {item.status == 'UNSTAKING' && (
                            <div className='remain-time' >
                                (남은시간: {unstakeWaitTime - moment().diff(moment(item.unstakingDate), 'hours')} Hour)
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    const bbtCoin = coinList.find(x=>x.coinType == 401);
    return (
        <div id='bbtStaking'>
            <Top />
            <Header />               
            <div className='content'>

                <div className='buttons'>
                    <div className='btn-dofreeze' onClick={()=>{setFreezeModalVisible(true)}}>{string.bbtDoFreeze}</div>
                    <div className='btn-dividenlist' onClick={dividend}>{string.bbtDividenList}</div>
                </div>

                <div className='mybalance'>
                    <div>Total Freezed</div>
                    <p className='balance'> {totalStaked} <span className='won'>BBT</span></p>
                    <p className='balance2'>(총수량: {bbtCoin ? bbtCoin.balance + totalStaked : '-'} BBT)</p>
        
                </div>

                <div className="list-container">
                    {list.map(item => {
                        if (!item) return;
                        return renderFlatList(item);
                    })}
                </div>               
                    
                <div className="page-container">
                    <Pagination
                    onChange={handlePageChange}
                    defaultCurrent={1}
                    pageSize={4}
                    total={pagination.total} />

                </div>
            </div>
            <Modal
                title={string.staking}
                visible={freezeModalVisible}
                onOk={create}
                onCancel={()=>{setFreezeModalVisible(false)}}
            >
                <p style={{marginBottom: '10px'}}>{string.bbtFreezeExplain}</p>   
                <p style={{marginBottom: '10px'}}>{stringFormat(string.bbtUnFreezeExplain,[unstakeWaitTime])}</p>   
                
                <Input
                    autoComplete='false'
                    value={freezeAmount}
                    onChange={(e)=>{setFreezeAmount(e.target.value)}}
                />
                {freezeErrorMsg && (
                    <p style={{color: 'red'}}>{freezeErrorMsg}</p>   
                )}
            </Modal>
        </div>
    )
}
export default Staking;
