import React, { Component, useState, useEffect, useRef  } from "react";

import { Form, Table, DatePicker, Input, Modal, Pagination, Select } from "antd";

import "../css/transaction.scss";
import "../css/transaction_m.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, withRouter } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import CoinSelectBox from "../components/common/CoinSelectBox";
import con from '../const';
import { formatBalance, multiplyBalance, stringFormat } from "../lib/util/coinUtil";
import moment from "moment";
import Top from './Top';
import Header from '../components/Header';
import MyTransaction from "./MyTransaction";


const Transaction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userinfo = reactLocalStorage.getObject(con.appCode + "#userinfo"); 
  const coinList = useSelector(state => state.coinListReducer);
  const language = useSelector(state => state.stringReducer.language);
  const string = useSelector(state => state.stringReducer.string);
  
  const [myTransactionVisible, setMyTransactionVisible] = useState(0)
  const [toCoinType, setToCoinType] = useState('')
  const [fromCoinType, setFromCoinType] = useState('')
  const [status, setStatus] = useState('')
  const [exchangeList, setExchangeList] = useState([])
  const [pagination, setPagination] = useState({ total: 0, current: 1 })
  const [modalOpen, setModalOpen] = useState(0)
  const [fromRegCoinType, setFromRegCoinType] = useState('')
  const [toRegCoinType, setToRegCoinType] = useState('')
  const [selCoinAmount, setSelCoinAmount] = useState('')
  const [getCoinAmount, setGetCoinAmount] = useState('')
  const [exchangeFee, setExchangeFee] = useState(0)
  const [exchangeCancelFee, setExchangeCancelFee] = useState(0)
  const [feeAmount, setFeeAmount] = useState(0)
  
  const selectedIdxForApply = useRef(0);
  const selectedIdxForCancel = useRef(0);

  useEffect(()=>{
    getExchangeList(pagination);
    httpGet(httpUrl.exchangeSetting, []).then(result => {
      setExchangeFee(result.data.fee);
      setExchangeCancelFee(result.data.cancelFee);
    });
  },[])

  const handleTableChange = (p, filters, sorter) => {
    const pager = { ...pagination, current: p.current };
    setPagination(pager);
    getExchangeList(pager);
  };

  const handlePageChange = (page, pageSize) => {
    const pager = { ...pagination, current: page, total: pageSize };
    setPagination(pager);
    getExchangeList(pager);
  };

  const initialLoad = () => {
    setExchangeList([]);
    setFromCoinType('');
    setToCoinType('');
    setFromRegCoinType('');
    setToRegCoinType('');
    setStatus('');
    setPagination({
      total: 0,
      current: 1
    });
    setMyTransactionVisible(0);
    setSelCoinAmount('');
    setGetCoinAmount('');
    setExchangeFee(0);
    setExchangeCancelFee(0);
    setFeeAmount(0);

    getExchangeList(pagination);

  }

  const reload = () => {
    getExchangeList(pagination);
  }
  
  const getExchangeList = async (page) => {
    //pagination 파라미터처리
    let pageNum = page.current;
    const res = await httpGet(httpUrl.exchangeList, [10, pageNum, fromCoinType, toCoinType, status], {})
    const newPage = { ...page, current: res.data.currentPage, total: res.data.totalCount };
    setExchangeList(res.data.exchanges);
    setPagination(newPage)
  };

  const registExchange = async () => {
    const result = await httpPost(httpUrl.exchangeRegist, [], {
      fromCoinType: fromRegCoinType,
      fromAmount: multiplyBalance(selCoinAmount, coinList.find(x=>x.coinType == fromRegCoinType).decimal),
      toCoinType: toRegCoinType,
      toAmount: multiplyBalance(getCoinAmount, coinList.find(x=>x.coinType == toRegCoinType).decimal),
    })

    if (result.data == "SUCCESS") {
      closeModal();
      initialLoad();
    }
    else if (result.data == "NO_BALANCE") alert(string.exchangeRegistNoBalance);
    else alert(string.exchangeRegistFail);
  }

  const applyExchange = async () => {
    closeModal();
    const result = await httpPost(httpUrl.exchangeApply, [selectedIdxForApply.current], {});
    
    if (result.data == "SUCCESS") {
      initialLoad();
    }
    else if (result.data == "NO_BALANCE") alert(string.exchangeApplyNoBalance);
    else if (result.data == "SELF") alert(string.exchangeSelfFail);
    else if (result.data == "INVALID") alert(string.exchangeInvaild);
    else alert(string.exchangeRegistFail);
  }

  const cancelExchange = async () => {
    closeModal();
    const result = await httpPost(httpUrl.exchangeCancel, [selectedIdxForCancel.current], {});
    if(result.data) {
      initialLoad();
    }
    else alert(string.serverFail)
  }

  const changeMyTransactionVisible = () => {
    setMyTransactionVisible(0)
  } 

  const openTransaction = (idx) => {
    selectedIdxForApply.current = idx;
    setModalOpen(1)
  }

  const openCancel = (idx) => {
    selectedIdxForCancel.current = idx;
    setModalOpen(1)
  }

  const openSelReg = () => {
    if (!userinfo.idx) {
      alert(string.needLogin2);
      return;
    }
    setModalOpen(3)
    setSelCoinAmount('')
    setGetCoinAmount('')
  }

  const openSelRegDetail = () => {
    if (fromRegCoinType == '' || toRegCoinType == '') {
      alert(string.selectCoin);
      return;
    }
    if (fromRegCoinType == toRegCoinType) {
      alert(string.exchangeSameCoinFail);
      return;
    }
    if (selCoinAmount == null || selCoinAmount == '' || getCoinAmount == null || getCoinAmount == '') {
      alert(string.exchangeCoinInputAmount);
      return;
    }
    setModalOpen(4)
  }

  const closeModal = () => {
    setModalOpen(0)
  }

  return (
    <div id="transaction">
      <Top />
      <Header />
      <div className="transactionContent">
        {myTransactionVisible == 0 && (
          <>
            <div className="bodyPart">
            <div className="leftMenu">
              <div className="leftTabBox">
              <p className="leftTabText">{string.exchangeStatus}</p>
              <Select
                className="leftSelectBox"
                placeholder={string.viewAll}
                onChange={(value) => {
                  setStatus(value)
                }}
              >
              {string.exchangeStatusCode.map(data => {
                return (
                  <option className="selName" value={data.code}>{data.name}</option>
                );
              })}
              </Select>
              </div>
              <div className="leftTabBox">
              <p className="leftTabText">{string.exchangeSendValue}</p>
              <CoinSelectBox
                    className="leftSelectBox"
                    placeholder={string.viewAll}
                    onChange={(value) => {
                      setFromCoinType(value)
                    }}
                    value={fromCoinType == '' ? undefined : fromCoinType}
                    style={{ width: "160px" }}
              />
              </div>
              <div className="leftTabBox">
              <p className="leftTabText">{string.exchangeBuyCoin}</p>
              <CoinSelectBox
                    className="leftSelectBox"
                    placeholder={string.viewAll}
                    onChange={(value) => {
                      setToCoinType(value)
                    }}
                    value={toCoinType == '' ? undefined : toCoinType}
                    style={{ width: "160px" }}
              />
              </div>
              <div className="mainColorBtn" onClick={()=>reload()}>
                {string.search}
              </div>
            </div>
            <div className="rightContent">
              <div className="btnTab">
                <div className="mainColorBtnLarge" style={{ marginRight: '10px'}}
                    onClick={() => {
                      openSelReg();
                    }}
                > 
                  {string.exchangeRegist}  {/* 판매등록 */}
                </div>
                <div className="grayColorBtnLarge"
                    onClick={() => {
                      if (!userinfo.idx) {
                        alert(string.needLogin2);
                        return;
                      }
                      setMyTransactionVisible(1)
                    }}
                >
                  {string.exchangeMyList}  {/* 나의내역 */}
                </div>
              </div>                                                                                                                                                                                                                                                                         
              <div className="tableTr">
                <div className="innerLine"></div>
                <div className="trContent">
                  <div className="selCoinText">{string.exchangeSendValue}</div>
                  <div className="buyCoinText">{string.exchangeReceiveValue}</div>
                  <div className="tcText">{string.exchangeStatus}</div>
                </div>
                <div className="innerLine"></div>
              </div>
              <div className="tableTd">
                {exchangeList.map((value, index) => {
                  let fromCoinInfo = coinList.find(x => x.coinType == value.fromCoinType);
                  let toCoinInfo = coinList.find(x => x.coinType == value.toCoinType);
                  return (
                    <>              
                      <div className="transactionDetailBox">
                        <div className="transcatioin-container">
                          <div className="sellCoinContainer">
                            <div className="symbolWrapper">
                              <img className="coinImage" src={coinList.find((coin) => coin.coinType === value.fromCoinType).image}/>
                            </div>
                            <div className="coinBox">
                              <p className="coinName">
                                {coinList.find((coin) => coin.coinType === value.fromCoinType).name}
                              </p>
                              <p className="coinAmount">
                                {formatBalance(value.fromAmount, coinList.find(x=>x.coinType == value.fromCoinType).decimal, 4)}
                                <span className="coinSymbol">
                                {coinList.find((coin) => coin.coinType === value.fromCoinType).symbol}
                              </span>
                              </p>
                      
                              <p className="coinKo">
                                ({formatBalance(fromCoinInfo.price * value.fromAmount, coinList.find(x=>x.coinType == value.fromCoinType).decimal, 4)}{string.localCoin})
                              </p>
                            </div>
                          </div>

                          <div className="arrowBox">
                            <img className="arrow" src={require('../img/transaction/transactionDetailArrow.png')}/>
                          </div>

                          <div className="sellCoinContainer">
                            <div className="symbolWrapper">
                              <img className="coinImage" src={coinList.find((coin) => coin.coinType === value.toCoinType).image}/>
                            </div>
                            <div className="coinBox">
                              <p className="coinName">
                              {coinList.find((coin) => coin.coinType === value.toCoinType).name}
                              </p>
                              <p className="coinAmount">
                                {formatBalance(value.toAmount, coinList.find(x=>x.coinType == value.toCoinType).decimal, 4)}
                                <span className="coinSymbol">
                                {coinList.find((coin) => coin.coinType === value.toCoinType).symbol}
                                </span>
                              </p>                               
                              <p className="coinKo">
                                ({formatBalance(toCoinInfo.price * value.toAmount, coinList.find(x=>x.coinType == value.toCoinType).decimal, 4)}{string.localCoin})
                              </p>
                            </div>
                          </div>
                        </div>   
                        <div className="btn-container"> 
                          {value.status == "PENDING" && userinfo.idx == value.fromUserIdx && (
                              <div className="doCancelTransactionBox"
                                  onClick={() => {
                                    openCancel(value.idx)
                                  }}>
                                  {string.doCancel}
                              </div>
                          )}
                          {value.status == "PENDING" && userinfo.idx != value.fromUserIdx && (                          
                              <div className="doTransactionBox"
                                  onClick={() => {
                                    if (!userinfo.idx) {
                                      alert(string.needLogin2);
                                      return;
                                    }
                                    openTransaction(value.idx);
                                  }}>
                                    {string.exchangeCoinConfirmDlgTtl}
                              </div>                             
                          )}
                          {value.status == "MATCH" && (
                            <div className="completeTransactionBox">{string.exchangeStatusMatch}</div>
                          )}

                          {value.status == "CANCEL" && (
                            <div className="cancelTransactionBox">{string.exchangeStatusCancel}</div>
                          )}
                        </div>   
                        <div className="registData">
                              <p className="registDate">  {string.registDate} : {moment(value.createDate).format("YY-MM-DD hh:mm:ss")}</p>
                              <p className="registUser">  {string.register} : {value.fromUserId}</p>
                          </div>                 
                      </div>

                      {/* 모달창 - 종류는 state값으로 modalOpen */}

                      <Modal open={ modalOpen == 1 || modalOpen == 2 
                              || modalOpen == 3 || modalOpen == 4} 
                              onCancel={ closeModal }
                              footer={null}>
                              <div style={{paddingTop: '20px'}}>
                                {modalOpen == 1 && (
                                  <div className="doTransaction">
                                    {/* <div><img className="exMark" src={require('../img/transaction/exMark.png')}/></div> */}
                                    <div className="warnText">{string.exchangeApplyMessage}</div>
                                    <div className="buyConfirmBtn">  
                                      <div className="grayColorBtnLarge" style={{margin: '0px 20px'}} onClick={closeModal}>{string.cancel}</div>
                                      <div className="mainColorBtnLarge" style={{margin: '0px 20px'}} onClick={()=>applyExchange()}>{string.ok}</div>
                                    </div>
                                  </div>
                                )}
                                {modalOpen == 2 && (
                                  <div className="doTransaction">
                                    {/* <img className="exMark" src={require('../img/transaction/exMark.png')}/><br></br> */}
                                    <span className="warnText">{string.exchangeCancelMessage}</span>
                                    <div className="buyConfirmBtn">  
                                      <div className="grayColorBtnLarge" style={{margin: '0px 20px'}} onClick={closeModal}>{string.cancel}</div>
                                      <div className="mainColorBtnLarge" style={{margin: '0px 20px'}} onClick={()=>cancelExchange()}>{string.ok}</div>
                                    </div>
                                  </div>
                                )}
                                {modalOpen == 3 && (
                                    <div className="selCoinBox">  
                                    <div className="regCelCoinTextBox">
                                    {string.exchangeSendValue}
                                    </div>
                                    <div>
                                      <CoinSelectBox
                                            className="coinSelBox"
                                            placeholder={string.viewAll}
                                            onChange={(value) => {
                                              setFromRegCoinType(value)
                                            }}
                                            value={fromRegCoinType == '' ? undefined : fromRegCoinType}
                                      />
            
                                      
                                      <Input className="selCoinAmount"
                                          name="selCA"
                                          placeholder={string.enterAmount}
                                          onChange={(e)=>{
                                            setSelCoinAmount(e.target.value)
                                            setFeeAmount(e.target.value * exchangeFee)
                                          }}
                                          value={selCoinAmount}
                                      />
                                  </div>
                                    <div className="regCelCoinTextBox">
                                      {string.exchangeReceiveValue}
                                    </div> 
                                    <CoinSelectBox
                                          className="coinSelBox"
                                          placeholder={string.viewAll}
                                          onChange={(value) => {
                                            setToRegCoinType(value)
                                          }}
                                          value={toRegCoinType == '' ? undefined : toRegCoinType}
                                    />
                                  
                                    <Input className="getCoinAmount"
                                        name="getCA"
                                        placeholder={string.enterAmount}
                                        onChange={(e)=>{
                                          setGetCoinAmount(e.target.value)
                                        }}
                                        value={getCoinAmount}
                                    />
                                
                                    <span className="coinRegRuleText">
                                    {string.exchangePolicy01}({exchangeFee*100}%)<br></br>
                                    {string.exchangePolicy02}({exchangeCancelFee*100}%)<br></br>
                                    {string.exchangePolicy03}</span>
                                    <div className="regSelBtnDiv" onClick={openSelRegDetail}> 
                                        {string.exchangeRegist}
                                    </div>
                                  </div>
                                )}

                                {modalOpen == 4 && (
                                  <>
                                  <button className="backBtn2" onClick={openSelReg}></button>
                                  <div className="selRegDetail">
                                    {/* <img className="exMark" src={require('../img/transaction/exMark.png')}/> */}
                                    <div className="warnText">{string.exchangeCoinConfirmMessage}</div>
                                    <div className="coinTrans">
                                        <div className="selCoinTrans">
                                          <span className="coinTransText">{string.exchangeSendValue}</span><br></br>
                                          <div className="selDiv">
                                            <img className="transDetailImage" src={coinList.find((coin) => coin.coinType === fromRegCoinType).image}/>
                                            <div className="transDetailKind">
                                              <div><span className="transDetailName">{coinList.find((coin) => coin.coinType === fromRegCoinType).name}</span></div>
                                              <span className="transDetailSymbol">{coinList.find((coin) => coin.coinType === fromRegCoinType).symbol}</span>
                                            </div>
                                            <span className="transDetailCost">{selCoinAmount}</span>
                                          </div>
                                        </div>
                                        <div className="getCoinTrans">
                                          <span className="coinTransText">{string.exchangeReceiveValue}</span>
                                          <div className="getDiv">
                                            <img className="transDetailImage" src={coinList.find((coin) => coin.coinType === toRegCoinType).image}/>
                                            <div className="transDetailKind">
                                              <div><span className="transDetailName">{coinList.find((coin) => coin.coinType === toRegCoinType).name}</span></div>
                                              <span className="transDetailSymbol">{coinList.find((coin) => coin.coinType === toRegCoinType).symbol}</span>
                                            </div>
                                            <span className="transDetailCost">{getCoinAmount}</span>
                                          </div>
                                        </div>
                                    </div>
                                    <div className="showFeeAmount">
                                      {string.fee}&nbsp;&nbsp;
                                      {selCoinAmount * exchangeFee}&nbsp;
                                      {coinList.find((coin) => coin.coinType === fromRegCoinType).symbol}
                                    </div>
                                    <div className="warnText">
                                      {string.exchangeCoinConfirmMessageWarn}
                                    </div>

                                    <div className="selRegConfirmBtn">  
                                      <div className="grayColorBtnLarge" onClick={closeModal}>{string.cancel}</div>
                                      <div className="mainColorBtnLarge" onClick={registExchange}>{string.ok}</div>
                                    </div>
                                  </div>
                                  </>
                                )}
                                </div>
                              </Modal>
                  </>

                  )
                })}
                
              </div>
            </div>
            <Pagination
            onChange={handlePageChange}
            defaultCurrent={1}
            pageSize={10}
            total={pagination.total} />
          </div>
          </>
        ) ||
        (
          <MyTransaction changeMyTransaction={changeMyTransactionVisible} />
        )}
        
      </div>
    </div>
  );
}

export default Transaction;