import React, { Component } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import { Select } from "antd";
import { useSelector, useDispatch } from "react-redux";

const Option = Select.Option;

const CoinSelectBox = (props) => {
  const coinList = useSelector(state => state.coinListReducer);

  const renderOption = () => {
    const { coinType, exceptCoinType } = props;

    let result = [];
    if (exceptCoinType) {
      coinList = coinList.filter(
        coin => !exceptCoinType.includes(coin.coinType)
      );
    }
    for (let i = 0; i < coinList.length; i++) {
      let valid = false;
      if (coinType) {
        if (coinType.includes(coinList[i].coinType)) valid = true;
      } else valid = true;
      if (valid) {
        result.push(
          <Option value={coinList[i].coinType} 
                  key={`${coinList[i]}${i}`}
                  className="selOption"
                  >
            <span className="selName">{coinList[i].name}</span>
          </Option>
        );
      }
    }

    return result;
  };

  const { size, mode, style, placeholder, value, onChange } = props;

  return (
    <Select
      className="selBox"
      {...props}
      size={size}
      mode={mode}
      style={{ width: "120px", ...style }}
      placeholder={placeholder}
      value={value}
      onChange={value => {
        onChange(value, "coinType");
      }}>
      {renderOption()}
    </Select>
  );
}
export default CoinSelectBox;