import { Image, Modal, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { httpGet, httpUrl } from "../api/httpClient";
import { setCoinBalance, setCoinList, setCoinCurrency } from "../actions";
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import string from '../language/StringKo';
import Header from '../components/Header';
import con from '../const';
import Top from './Top';
import { useSelector, useDispatch } from "react-redux";
import logo from "../img/text-logo1.png";
import { reactLocalStorage } from "reactjs-localstorage";
import { formatBalance, multiplyBalance, stringFormat } from "../lib/util/coinUtil";

const MyWallet = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const coinList = useSelector(state => state.coinListReducer);   
    const language = useSelector(state => state.stringReducer.language);
    const string = useSelector(state => state.stringReducer.string);   
    const [balances, setBalances] = useState([]);
    const [blockchains, setBlockchains] = useState([]);
    const [exchangeRate, setExchangeRate] = useState(global.exchangeRate || []);
    let currency =  global.currency;
    let exchangeRateLoaded = false;

    const [noticePopup, setNoticePopup] = useState({});
    const [noticePopupVisible, setNoticePopupVisible] = useState(false);

    useEffect(()=>{
        requestSystemListBlockchain();
        exchangeRate.length === 0 && requestCurrencyExchangeRate().then(() => {
            setExchangeRate(global.exchangeRate)
        })
        checkNotice();
    },[]);

    const checkNotice = async () => {
        if (global.noticeChecked) return;
        global.noticeChecked = true;

        const res = await httpGet(httpUrl.noticeMainList, [language], {})
        if (res.data) {
            const popupClosedInfo = reactLocalStorage.getObject(con.appCode + "#popupClosedTime");
            if (popupClosedInfo) {
                if (popupClosedInfo.idx == res.data.idx) {
                    const diff = Date.now() - new Date(popupClosedInfo.time).getTime();
                    const diffInDays = diff / (1000 * 60 * 60 * 24);
                    if (diffInDays < 3) {
                      return;
                    }
                }
            }

            setNoticePopupVisible(true)
            setNoticePopup(res.data)
        }
    }

    const requestBalance = async () => {
        console.log("##### Request balance")
        httpGet(httpUrl.balance, [0], {}).then((result) => {
            result = result.data;
            console.log(result)
            dispatch(setCoinBalance(result));
        }).catch(e => {
            console.log("## requestBalance error: " + e)
        });
    };

    const requestSystemListBlockchain = () => {
            console.log("## Loading systemListBlockchain");
            httpGet(httpUrl.systemListBlockchain, [language], {}).then(async (result) => {
                result = result.data;
                console.log('## systemListBlockchain loaded');
                result.forEach(obj => {
                    obj.status = true;
                    obj.address = '';
                    obj.balance = 0;
                    obj.lockBalance = 0;
                    obj.balanceLoaded = false;
                    obj.price = 0;
                    obj.priceLoaded = false;
                    obj.value = 0;
                    obj.valueLoaded = false;
                    obj.purchaseFee = 0;
                    obj.sendInFee = 0;
                    obj.sendExFee = 0;
                    obj.tradeFee = 0;
                    obj.feeLoaded = false;
                    obj.serverWallet = '';
                    obj.serverWalletLoaded = false;
                    obj.change = 0.0;
                    obj.market = 0.0;
                    obj.trends = [];
                })
                dispatch(setCoinList(result));
                await requestCurrency();
                // this.setState({ blockchains: result })
            }).catch((e) => {
                console.log('## systemListBlockchain load error: ' + e)
            });

    }

    const requestCurrency = (hide) => {
        console.log("## Request currency");
        return new Promise((resolve, reject) => {
            httpGet(httpUrl.currencies, [], {}, hide).then(async (result) => {
                dispatch(setCoinCurrency(result.data));
                let coinCurrencyUpdateTime = new Date().toLocaleTimeString();
                let currencyLoaded = true;
                await requestBalance();
                resolve();
            }).catch(e => {
                console.log("## requestCurrency error: " + e);
                reject(e);
            });
        })
    }

    const requestCurrencyExchangeRate = (hide) => {
        if (con.enableForeignExchangeRate) {
            console.log("##### Request currencyExchangeRate")
            return new Promise((resolve, reject) => {
                httpGet(httpUrl.currencyExchangeRate, [], {}, hide).then((result) => {
                    console.log("## Request currencyExchangeRate loaded")
                    result = result.data;
                    global.exchangeRate = [
                        1,
                        result.find(x => x.currency == 'KRWUSD').rate,
                        result.find(x => x.currency == 'KRWCNY').rate,
                        result.find(x => x.currency == 'KRWJPY').rate,
                    ]
                    exchangeRateLoaded = true;
                    resolve();
                }).catch(e => {
                    console.log("## requestCurrencyExchangeRate error: " + e)
                    reject();
                });
            })
        } else {
            return new Promise((resolve, reject) => {
                exchangeRateLoaded = true;
                global.exchangeRate = [1, 1, 1, 1];
                resolve();
            })
        }
    }

    const popupNoshow = async (idx) => {
        setNoticePopupVisible(false)
        reactLocalStorage.setObject(con.appCode + "#popupClosedTime", {
            idx: idx,
            time: new Date().toISOString()
        });
    }
    const calTotalValue = () => {
        // let totalValue = 0;
        // let allLoaded = true;
        // coinList.forEach(coin => {
        //     allLoaded &= coin.valueLoaded;
        //     totalValue += coin.value;
        // });
        // return (totalValue * exchangeRate[currency]).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        if (!coinList || coinList.length <= 0) return 0;
        const coin = coinList.find(x=>x.coinType == 402);
        return (coin.value * exchangeRate[currency]).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    return (
        <div id='mywallet'>
              <Top/>
             <Header />
             <div className='mybalance'>
                <div>{string.myTotalAsset}</div>
                {/* <p className='balance'> {calTotalValue()} <span className='won'>원</span></p> */}
             </div>

             {coinList.map((value, index) => {
                if (value.coinType == 4) return;
                if (value.coinType == 403) return;
                let balance = parseFloat(Math.round(value.balance * 10000) / 10000).toFixed(4);
                let lockBalance = parseFloat(Math.round(value.lockBalance * 10000) / 10000).toFixed(4);
                
                let coinName = value.name;
                if (value.coinType == 401) {
                    coinName = language == 'ko' ? '블럭볼' : 'BlockBall'
                }
                if (value.coinType == 402) {
                    coinName = language == 'ko' ? '테더' : 'Tether'
                }
                return (
                    <div className="coinTable" key={index} onClick={()=>{navigate('/myWalletDialog', {state: {coinType: value.coinType}})}}>                              
                        <div className="container">
                            <div className='title'>
                                <div className="coinImg"><img src={value.image}/></div>
                                {coinName}
                            </div>
                            <div className="balance">
                                <div className="price"> {balance}<span style={{marginLeft: 6, fontWeight: 300}}>{value.symbol}</span>  </div>
                                {lockBalance > 0 && (
                                    <div className="lockBalance"> 
                                        <div><UnlockOutlined /> {balance-lockBalance}</div>
                                        <div style={{backgroundColor:'#fff', height: '1px'}}></div>
                                        <div><LockOutlined /> {lockBalance}</div>
                                    </div>)}
                                    {/* {value.coinType == 401 ? (
                                        <div className='rate'>-</div>
                                    ) : (
                                <div className='rate'>{(value.value * exchangeRate[currency]).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}{string.krw} </div>
                                    )} */}
                            </div>

                        </div>                               
                    </div>
                );
            })}
            <Modal
                title={noticePopup.title}
                visible={noticePopupVisible}
                onOk={()=>setNoticePopupVisible(false)}
                onCancel={()=>setNoticePopupVisible(false)}
                footer={[
                    <Button key="back" onClick={()=>popupNoshow(noticePopup.idx)}>
                      {stringFormat(string.popupNoshow,[3])} 
                    </Button>,
                    <Button type="primary" onClick={()=>setNoticePopupVisible(false)}>
                      {string.close}
                    </Button>,
                  ]}
            >
            <p style={{marginBottom: '10px'}}>{noticePopup.content}</p>   
            </Modal>
        </div>
    )
}

export default MyWallet;