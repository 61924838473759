import React, { Component, useState, useEffect, useRef, Fragment  } from "react";

import { Form, Table, DatePicker, Input, Button, Pagination, Select } from "antd";

import "../css/transaction.scss";
import "../css/transaction_m.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, withRouter } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import CoinSelectBox from "../components/common/CoinSelectBox";
import { formatBalance, multiplyBalance, stringFormat } from "../lib/util/coinUtil";
import moment from "moment";
import con from '../const';
import Coin from '../lib/util/coin';

const MyTransaction = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userinfo = reactLocalStorage.getObject(con.appCode + "#userinfo"); 
    const coinList = useSelector(state => state.coinListReducer);
    const language = useSelector(state => state.stringReducer.language);
    const string = useSelector(state => state.stringReducer.string);

    const [actionType, setActionType] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [exchangeFee, setExchangeFee] = useState(0);
    const [exchangeCancelFee, setExchangeCancelFee] = useState(0);
    const [pagination, setPagination] = useState({ total: 0, current: 1 });

    useEffect(()=>{
        getMyExchangeList(pagination, actionType);
        httpGet(httpUrl.exchangeSetting, [])
            .then(result => {
                setExchangeFee(result.data.fee);
                setExchangeCancelFee(result.data.cancelFee);
            })
            .catch();
    },[])

    const back = () => {
        props.changeMyTransaction(0)
    };

    const getMyExchangeList = (page, type) => {
        httpGet(httpUrl.exchangeMyList, [10, page.current, type])
            .then(result => {
                result = result.data;

                setTableData(result.exchanges);
                setRefreshing(false);
                setPagination({...pagination, current: result.currentPage, total: result.totalCount})
            })
            .catch();
    }

    const handlePageChange = (page, pageSize) => {
        setRefreshing(true);
        setPagination({...pagination, current: page, total: pageSize})   
        getMyExchangeList({...pagination, current: page, total: pageSize}, actionType)
    };

    const handleRefresh = () => {
        setRefreshing(true);
        getMyExchangeList(pagination);
    }

    const reload = () => {
        setTableData([])
        setPagination({total: 0, current: 1});
        getMyExchangeList({total: 0, current: 1}, actionType);
    }
    const cancelExchange = (idx, amount, symbol) => {
        let stringMsg = string.exchangeCancelMessage;
        if (exchangeCancelFee > 0) {
            stringMsg += "\n" + string.exchangeCancelFee + ": " + (amount * exchangeCancelFee) + ' ' + symbol;
        }
        if (global.confirm(stringMsg)) {
            httpPost(httpUrl.exchangeCancel, [idx], {}).then((result) => {
            if(result.data) {
                reload();
            }
            else alert(string.serverFail)
            }).catch(() => {
            });
        }
    }
    const renderFlatList = (item) => {
        let fromCoinInfo = coinList.find(x => x.coinType == item.fromCoinType)
        let toCoinInfo = coinList.find(x => x.coinType == item.toCoinType)
        let statusString = {
            PENDING: string.exchangeStatusPending,
            CANCEL: string.exchangeStatusCancel,
            MATCH: string.exchangeStatusMatch,
        };

        let fromCoinString = '';
        let toCoinString = '';
        if (currentTab == 0) {
            fromCoinString = string.exchangeSendValue;
            toCoinString = string.exchangeReceiveValue;
        }
        else if (currentTab == 1) {
            fromCoinString = string.exchangeBuyCoin;
            toCoinString = string.exchangeSentCoin;
        }
        return (
            <div className="mytran-list-item-container">
                <div className="mytran-list-item-detail">
                    <div className="mytran-list-item-coin">
                        {fromCoinString}: <span className="mytran-list-item-coin-num1">{(item.fromAmount / Coin.getCoinUnit(item.fromCoinType).base).toFixed(4)}</span> <span className="mytran-list-item-symbol">{fromCoinInfo.symbol}</span>
                    </div>
                    <div className="mytran-list-item-coin"> {toCoinString}: <span className="mytran-list-item-coin-num2">{(item.toAmount / Coin.getCoinUnit(item.fromCoinType).base).toFixed(4)}</span> <span className="mytran-list-item-symbol">{toCoinInfo.symbol}</span> </div>
                </div>
                <div className="mytran-list-item-detail">
                    <div className="mytran-list-item-fee"> {string.fee}: {(item.fee * item.fromAmount / Coin.getCoinUnit(item.fromCoinType).base).toFixed(4)} {fromCoinInfo.symbol} </div>
                    <div className="mytran-list-item-date"> {string.exchangeRegistDate}: {moment(item.createDate).format("YY-MM-DD hh:mm:ss")}</div>
                </div>
                <div className="mytran-list-item-status">
                    <div className="">
                        <div className="">
                            <div className="" onPress={() => { }}>
                                <div className="mytran-list-item-status-text">
                                    {statusString[item.status]}
                                </div>
                            </div>
                            {item.status == "PENDING" && (
                                <div className="mytran-list-item-status-cancel" onClick={() => { cancelExchange(item.idx, (item.fromAmount / Coin.getCoinUnit(item.fromCoinType).base).toFixed(2), fromCoinInfo.symbol); }}>
                                    {string.doCancel}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    return (
        <Fragment>
            <div className="mytran-header-container">
                <div className="mytran-header-back-wrapper" onClick={() => { back(); }}>
                    <img src={require('../img/transaction/backBtn.png')} />
                </div>
                <div className="mytran-top-refresh" onClick={reload} style={{paddingLeft: '30px', paddingTop: '10px'}}>
                    <img src={require('../img/transaction/refreshBtn.png')}/>
                </div>
                <div className="mytran-header-button-wrapper">
                    <div className={actionType == 0 ? 'mainColorBtnLarge' : 'grayColorBtnLarge'} style={{marginRight: 10}} onClick={() => {
                        setActionType(0);
                        setTableData([]);
                        setCurrentTab(0);
                        setPagination({total: 0, current: 1});
                        getMyExchangeList({total: 0, current: 1}, 0);
                    }}>{string.exchangeRegist} </div>
                    <div className={actionType == 1 ? 'mainColorBtnLarge' : 'grayColorBtnLarge'} onClick={() => {
                        setActionType(1);
                        setTableData([]);
                        setCurrentTab(1);
                        setPagination({total: 0, current: 1});
                        getMyExchangeList({total: 0, current: 1}, 1);
                    }}>{string.exchangeMyApply}</div>
                </div>
            </div>

            <div className="mytran-list-container">
                {tableData.map(item => {
                    if (!item) return;
                    return renderFlatList(item);
                })}
            </div>               
        
            <Pagination
            onChange={handlePageChange}
            defaultCurrent={1}
            pageSize={10}
            total={pagination.total} />
        </Fragment>
    );
}


export default MyTransaction;
