import React, { useEffect, useState } from 'react';
import Top from './Top';
import Header from '../components/Header';
import { Select, Input, Button, Modal, Pagination  } from 'antd';
import { httpGet, httpPost, httpUrl } from '../api/httpClient';
import { useSelector, useDispatch } from "react-redux";
import { setCoinBalance, setCoinList, setCoinCurrency } from "../actions";
import SHA256 from 'crypto-js/sha256';
import { enc } from 'crypto-js';
import moment from "moment";
import Const from '../const';

const Deposit = () => {
    const dispatch = useDispatch();
    const userinfo = useSelector(state => state.loginReducer.userinfo); 
    const coinList = useSelector(state => state.coinListReducer);
    const string = useSelector(state => state.stringReducer.string);
    const [page, setPage] = useState({
        totalCount:0,
        pageSize: 5,
        current: 1,
    });
    const [list, setList] = useState([]);
    const [inputs, setInputs] = useState({
        bankName:'',
        amount:0,
    })
    const { bank, bankAddress, amount, bankName, secPassword } = inputs;
    useEffect(()=>{
        getList();
        requestBalance();
    },[])
    const requestBalance = async () => {
        console.log("##### Request balance")
        httpGet(httpUrl.balance, [202], {}).then((result) => {
            result = result.data;
            console.log(result)
            dispatch(setCoinBalance(result));
        }).catch(e => {
            console.log("## requestBalance error: " + e)
        });
    };
    const onSubmit = async() => {
        if (amount < 1 || bankName === '') {
            Modal.error({
                title: string.error,
                content: string.bankDepositInputError
            })
            return;
        }

        Modal.confirm({
            title: string.bankDepositApply,
            content: amount + ", " + string.bankDepositApplyConfirm,
            okText: string.ok,
            cancelText: string.cancel,
            onOk(){
                httpPost(httpUrl.depositRequest, [], {
                    amount:amount, 
                    bankName:bankName,
                }).then((res)=>{
                    if (res.data == "SUCCESS" && res.result == "SUCCESS") {
                        setInputs({
                            ...inputs,
                        })
                        getList();
                        Modal.info({
                            title: string.bankDepositApply,
                            content: string.bankDepositApplySuccess
                        })

                    } else{
                        let message = string.errorDetail
                        if (res.data == "INVALID_AMOUNT") message = string.bankDepositApplyInvalidAmount;
                        Modal.info({
                            title: string.bankDepositApply,
                            content: message
                        })
                        return;
                    }
                })},
            oncancel(){
                return;
            }           
        });
    };
    const getList = async (current) => {
        if (current == null) current = page.current;
        const res = await httpGet(httpUrl.getBankDepositList, [current, page.pageSize], {});
        if (res.data.list.length > 0) {
            setList(res.data.list);
            setPage({...page, totalCount: res.data.totalCount, current});
        }
    };
    const handleTableChange = (current) => {   
        getList(current)
    };
    // const onDeleted = (idx) => {       
    //     Modal.confirm({
    //         title: "거래 신청 취소",
    //         content: "신청을 취소하시겠습니까? 확인을 누르시면 내역은 삭제됩니다.",
    //         okText: string.ok,
    //         cancelText: string.cancel,
    //         onOk(){
    //             httpPost(httpUrl.deletedDeposit, [idx], {}).then((res)=>{
    //                 if (res.data == "SUCCESS" && res.result == "SUCCESS") {
    //                     alert("신청이 취소되었습니다.")
    //                     getList();
    //                 } else{
    //                     alert("신청에 오류가 생겼습니다. 관리자에게 문의해주세요.")
    //                     return;
    //                 }
    //             })},
    //         oncancel(){
    //             return;
    //         }           
    //     });
    // }
    return (
        <div id='trade'>
            <Top />
            <Header />           
            <div className='inputSection'>
                <div className='inputBox'>
                    <p className='name'>{string.coin} ({string.myValue}: {coinList.find(x=>x.coinType == 202).balance} ABP)</p>
                </div>
                <div className='inputBox'>
                    <p className='name'>{string.bankDepositDepositor}</p>
                    <Input 
                    placeholder={string.bankDepositDepositorInput}
                    name="bankName"
                    value={bankName}
                    onChange={(e)=>{
                        setInputs({...inputs, bankName: e.target.value })
                    }} />
                </div>
                <div className='inputBox'>
                    <p className='name'>USD</p>
                    <Input 
                    placeholder={string.pleaseInsertAmount}
                    name="amount"
                    value={amount}
                    onChange={(e)=>{
                        let a = e.target.value.replace(/[^0-9]/g,'')
                        setInputs({...inputs, amount: a })
                    }} />

                </div>
                <Button className='btn' onClick={onSubmit}>{string.bankDepositApply}</Button>
            </div>            
            <div className='table'>
                <p className='title'>History</p>
                {(!list || list.length == 0) && <div style={{width: '100%', textAlign: 'center'}}>nodata</div>}
                {/* <div className='depositColumn'>
                    <div>{string.bankDepositStatus}</div>
                    <div>{string.bankDepositDepositor}</div>
                    <div>{string.bankDepositApplyAmount}</div>
                    <div>{string.bankDepositApplyTime}</div>
                </div> */}
                {list.map((item)=>{
                    return(
                        <div key={item.idx} className='depositRow'>
                            <div key={item.idx} className='depositRow-item'>
                                <div className={'status'+item.status}>{item.status === 0 ? 'Processing' : item.status === 1 ? string.completed : string.cancel}</div>
                                <div>{moment(item.createDate).format("YYYY-MM-DD hh:mm:ss")}</div>
                            </div>
                            <div key={item.idx} className='depositRow-item'>
                                <div>{item.bankName}</div>
                                <div>{item.amount}</div>
                            </div>
                            {/* <div><Button onClick={()=>onDeleted(item.idx)} disabled={item.status > 0}>삭제</Button></div> */}
                        </div>
                    )
                })}               
            </div>
            <div className='custompage'>
                <Pagination current={page.current} onChange={handleTableChange} defaultPageSize={page.pageSize} total={page.totalCount} />
            </div> 
        </div>
    )
}
export default Deposit;
