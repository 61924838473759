import React, { Component, useState } from "react";
import { Modal, Descriptions, Input } from "antd";
import { render } from "less"; 
import { httpUrl, httpPost } from "../../api/httpClient";
import { useDispatch, useSelector } from "react-redux";

const Ditems = Descriptions.Item;

const InquiryDetailModal = (props) => {
    const [title, setTitle] = useState('')
    const onCancel = () => {
        props.onCancel();
    };
  const string = useSelector(state => state.stringReducer.string);   
  const { visible, data, onClose } = props;
  return (
    <Modal
        visible={visible}
        title={props.title}
        onOk={()=>{
            httpPost(httpUrl.inquiryCreate, [], {
                userIdx:data.idx,
                title: title
            }).then((res) =>{
                if(res.data === "SUCCESS"){
                    Modal.info({
                        title: string.regist,
                        content: string.registComplete
                    })
                    onClose()
                } else {
                    Modal.error({
                        title: string.error,
                        content: string.errorDetail,
                    });
                }
            })
        }}
        onCancel={onClose}        
        okText={string.regist}
        cancelText={string.cancel}  
        style={{ minWidth: 'auto', fontFamily: 'Noto Sans CJK KR'}}
    >
        <Descriptions
            bordered
            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
        >            
            <Ditems label={string.writer} span={2} labelStyle={{textAlign: "center", fontFamily: 'Noto Sans CJK KR'}}>
               <p style={{fontFamily: 'Noto Sans CJK KR'}}>{data.id}</p>
            </Ditems>
            <Ditems label={string.inquiryDetails} span={2} labelStyle={{textAlign: "center", fontFamily: 'Noto Sans CJK KR'}}>
               <Input.TextArea
               rows={4}
               value={title}
               style={{
                fontFamily: 'Noto Sans CJK KR'
               }}
               onChange={(e) => {
                 setTitle(e.target.value)  
            }}

               />
            </Ditems>
  
        </Descriptions>
    </Modal>
);
}

export default InquiryDetailModal;